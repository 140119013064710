@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --color-primary: 132 204 22; /* light green */
    --color-secondary: 163 230 53; /* lighter green */
    --color-accent: 101 163 13; /* darker green */
    --color-background: 240 253 244; /* very light green */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-lime-50;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}